<script>
import { siteService } from "../../../helpers/backend/site.service";
import { machineService } from "../../../helpers/backend/machine.service";
// import maps from "../component/maps.vue";
import DualListBox from "dual-listbox-vue";
import "dual-listbox-vue/dist/dual-listbox.css";
export default {
  components: {
    // maps,
    DualListBox,
  },
  data() {
    return {
      form: {
        id: null,
        name: "",
        address: "",
        city: "",
        coordinate: {
          lat: -6.191435,
          lng: 106.844124,
        },
      },
      busy: false,
      show: true,
      showDismissibleAlert: false,
      error: "",
      active: false,
      source: [],
      destination: [],
    }
  },
  computed: {
    isUpdateState() {
      return this.form.id != null
    },
  },
  mounted() {
    this.reset()
  },
  methods: {
    onChangeList: function ({ source, destination }) {
      this.source = source
      this.destination = destination
    },

    onSubmit(event) {
      event.preventDefault()
      this.busy = true
      this.form.machine_ids = this.destination.map((d) => {
        return d.id
      })
      siteService.createOrUpdate(this.form).then(
        (data) => {
          if (data != null) {
            this.reset()
            this.$emit("refresh")
            this.showDismissibleAlert = true
            this.error = ""
            this.setActive(false)
          }
          this.busy = false
        },
        (error) => {
          this.showDismissibleAlert = true
          this.error = "Failed to Insert/Update Site. Error : " + error
          this.busy = false
        }
      )
    },
    setPosition(position) {
      this.form.coordinate = position
    },
    reset() {
      // Reset our form values
      this.form.id = null
      this.form.name = ""
      this.form.address = ""
      this.form.city = ""

      this.form.coordinate = {
        lat: -6.191435,
        lng: 106.844124,
      }
      this.$refs.mapsRef.setPosition(
        this.form.coordinate.lat,
        this.form.coordinate.lng
      )
      // Trick to reset/clear native browser form validation state
      this.show = false
      this.showDismissibleAlert = false
      this.error = ""
      this.$nextTick(() => {
        this.show = true
      })
      this.destination = []
      machineService.getAllMachine({ site_id: -1 }).then((data) => {
        if (data.machines) {
          this.source = data.machines.map((m) => {
            return { name: m.serial_number, id: m.id }
          })
        }
      })
    },
    onReset(event) {
      event.preventDefault()
      this.setActive(false)
      this.reset()
    },
    setActive(b, outside_triger) {
      this.active = b
      if (outside_triger) return
      this.$emit("active_change", b)
    },
    initUpdate(id) {
      this.setActive(true)

      this.$nextTick(() => {
        this.reset()
        this.$refs.name.$el.focus()
        this.busy = true
        machineService.getAllMachine({ site_id: -1 }).then((data) => {
          if (data.machines) {
            this.source = data.machines.map((m) => {
              return { name: m.serial_number, id: m.id }
            })
          }
        })
        machineService.getAllMachine({ site_id: id }).then((data) => {
          if (data.machines) {
            this.destination = data.machines.map((m) => {
              return { name: m.serial_number, id: m.id }
            })
          }
        })

        siteService.getById(id).then(
          (data) => {
            if (data.site != null) {
              this.form.id = data.site.id
              this.form.name = data.site.name
              this.form.city = data.site.city
              this.form.address = data.site.address

              this.form.coordinate = data.site.coordinate
              this.$refs.mapsRef.setPosition(
                this.form.coordinate.lat,
                this.form.coordinate.lng
              )
            } else {
              this.showDismissibleAlert = true
              this.error =
                "Failed to get site type to update. Error : Site not found"
            }
            this.busy = false
          },
          (err) => {
            this.showDismissibleAlert = true
            this.error = "Failed to get site to update. Error : " + err
            this.busy = false
          }
        )
      })
    },
    invokeCreate() {
      this.setActive(true)
    },
  },
};
</script>


<template>
  <div v-if="this.active">
    <b-alert
      :variant="error == '' ? 'success' : 'danger'"
      dismissible
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
    >
      <div v-if="error != ''">
        {{ error }}
      </div>
      <div v-else>Success perform Insert/Update action</div>
    </b-alert>
    <div class="card">
      <div class="card-body">
        <div>
          <b-form @submit="onSubmit" @reset="onReset" v-if="show" ref="form">
            <b-overlay :show="busy" rounded="lg" opacity="0.6">
              <b-form-group
                id="input-group-1"
                label="Site Name:"
                label-for="input-1"
              >
                <b-form-input
                  id="input-1"
                  v-model="form.name"
                  type="text"
                  placeholder="Site Name"
                  required
                  ref="name"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                id="input-group-2"
                label="Address:"
                label-for="input-2"
              >
                <b-form-input
                  id="input-2"
                  v-model="form.address"
                  type="text"
                  placeholder="Address"
                  required
                ></b-form-input>
              </b-form-group>
              <b-form-group
                id="input-group-3"
                label="City:"
                label-for="input-3"
              >
                <b-form-input
                  id="input-3"
                  v-model="form.city"
                  type="text"
                  placeholder="City"
                  required
                ></b-form-input>
              </b-form-group>

              <!-- <maps
                :coordinate="this.form.coordinate"
                ref="mapsRef"
                @changePosition="setPosition"
              /> -->
              <DualListBox
                :source="source"
                :destination="destination"
                label="name"
                @onChangeList="onChangeList"
              />
              <br />
              <b-button class="mr-2" type="submit" variant="primary">
                {{ isUpdateState ? "Update" : "Submit" }}
              </b-button>
              <b-button type="reset" variant="danger">Cancel</b-button>
            </b-overlay>
          </b-form>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <b-form-group>
      <b-button variant="primary" @click="invokeCreate">Create new</b-button>
    </b-form-group>
  </div>
</template>



